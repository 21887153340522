ul {
    list-style: none;
}

p {
    margin-left: 32px;
}

/* title */
.title {
    width: 390px;
    height: 38px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    font-style: normal;
    font-weight: bolder;
    font-size: 25px;
}

.riskimage {
    height: 30px;
    margin-left: 10px;
    margin-top: 10px;
}

.riskarea {
    flex-direction: row;
    display: flex;
    font-size: 12px;
    margin-top: 20px;
}

.riskcontent {
    margin-left: 15px;
}

.topcontainer {
    display: flex;
    flex-direction: row;
}

.riskrating {
    margin-left: 50rem;
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
}

.sidenav {
    height: 100%;
    width: 100%;
    position: relative;
    transition: 0.5s ease;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 25px;
    justify-content: flex-start;
}

.sidenavitems:hover {
    background-color: #d9d9d9;
    padding-left: 5px;
    /* padding-top: 0.5rem; */
    /* height: 3%; */
}

.sidenavselected {
    background-color: #d9d9d9;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2px;
}

.maincotainer {
    display: flex;
    flex-direction: row;
}

.processcontainer {
    margin-left: 3rem;
    width: 85%;
    border: 1px solid black;
    padding: 50px;
    /* margin: 20px; */
}

.creepcontainer {
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
    width: 85%;
    border: 1px solid black;
    padding: 50px;
    /* margin: 20px; */
}

.issuetable {
    flex: 1;
}

.sidenavitems {
    padding-bottom: 1rem;
    background-color: white;
    padding-left: 2px;
    padding-top: 1rem;
}

.sidenavsection {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.selectindicator {
    background-color: red;
    margin-left: 2px;
}

.lineplotcontainer {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-gap: 200px;
    padding-right: 1rem;
}

.lineplotitem {
    padding: 20px;
    font-size: 30px;
    width: 9px;
    height: 8px;
}

/* login */
.height-100>div {
    height: 99.9vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

.height-100 .bg-black-light {
    background-color: rgb(15, 15, 15);
}

.height-100 .input-group-text,
.height-100 .form-control {
    /* background-color: #282c30 !important; */
    color: gray !important;
    /*border: 0px !important;*/
}

/* .input-group {
  min-width: 200;
} */
.height-100 .size-a {
    font-size: 12px !important;
}

.row {
    margin: 0.1px;
}

.scroll {
    margin: 0px !important;
}

.loginModule {
    width: 340px;
   /* background-color: rgb(249, 249, 249);*/
     background-color:#fff;
    border: .2px solid #c1c2c5;
    border-radius: 5px;
   /* box-shadow:5px 3px #d5d5d5;*/
}

.error {
    position: absolute;
    top: 45px;
    line-height: 15px;
    left: 10px;
}

.relative {
    position: relative;
}

.passwordWrap {
    position: absolute;
    top: -25px;
    right: 15px;
}

.btnReset {
    margin-top: 20px;
}

/* The SVG covers the entire window */
svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/* Prevent accidentally filled polygons or paths */
svg path,
svg polygon,
svg polyline {
    fill: none !important;
    stroke: none !important;
}

/* Default line styling */
line {
    stroke: #00c0ff;
    /* bright blue color */
    stroke-width: 1;
    /* we may override width if needed */
}

.visualization-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.logincomp {
    z-index: 999;
}
.aifeel{ position: fixed; background-color: #010;}
.z-200{ z-index: 200;}
.form-signin{width: 100%;
    max-width: 340px;
    padding: 15px;
    margin: 0 auto;}
    .left-0{ left: -10px !important; position: relative;}

.loginModule input{ border: solid 1px #ced4da !important;}
.loginModule .pwcont .input-group-text{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 38px;
}
.pr-0{ padding-right: 0px !important;}
.top-40{ top:40px;}
.left-0{ left: 0px !important;}
.paraFgt{ margin-left: 0px; font-size: 13px;}
.leftN{left:0px !important}

.z-2{
    z-index: 2;
}

#otp::-webkit-inner-spin-button,
#otp::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#otp {
    -moz-appearance: textfield; /* For Firefox */
}

.back-to-login {
    margin-left: 5px;
}